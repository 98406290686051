import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Typography } from '@mui/material';
import * as CommonMethods from "../../../utills/CommonMethods";
import * as API from '../../../utills/API';
// import useFormData from '../../../utills/hooks/useFormData'
import SkeletonRegpopup from '../../../components/Skeletons/SkeletonRegpopup'
function SourcedProfileRegistrationDetails(props) {
  const [registrationDetails, setRegistrationDetails] = useState({});
  const [isLoaded, setIsloaded] = useState(false);

  //const params = useParams();
  // const formData = useFormData();
  const [fileLink, setFileLink] = useState(null);

  // let navigate = useNavigate();
  useEffect(() => {
    fetchRegistrationDetails();
  }, [])
  const fetchRegistrationDetails = () => {
    if (!CommonMethods.isNullOrUndefined(props?._id)) {
      if (props?.type === 'archive') {
        setIsloaded(false);
        API.GetMethod('webRegistration/web/sourced/' + props?._id).then(response => {
          setIsloaded(true);
          setRegistrationDetails(response.data);
          setFileLink(process.env.REACT_APP_BASE_URL + response.data?.attachments?.resume?.download_path);
        })
      }
      else {
        setIsloaded(false);
        API.GetMethod('webRegistration/web/' + props?._id).then(response => {
          setIsloaded(true);
          setRegistrationDetails(response.data[0]);
          setFileLink(process.env.REACT_APP_BASE_URL + response.data[0]?.attachments?.resume?.download_path);
        })
      }
    }
  }

  return (
    <div className="d-flex flex-column flex-grow-1 mer-overflow mer-overflow-x-hidden">
      {isLoaded ? (
        <Card className="d-flex flex-column">
          <div className="d-flex flex-row align-items-center justify-content-between mer-margin-left-16 mer-margin-right-16 mer-margin-top-16">
            {/* <div className='mer-font-bold'>Registration Details</div> */}
            <Typography variant="h6">Registration Details</Typography>
            {/* <Tooltip title={formData?.can_edit ? '' : Messages?.NO_EDIT} >
            <div>
              <Button disabled={formData?.can_edit ? false : true} variant='contained'  className=''
                onClick={() => {
                  // props.onChange();
                  // navigate(StaticRouterLinks.registration_details_form + params.id) 
                }}>Edit</Button>
            </div>
          </Tooltip> */}
          </div>
          <div className="d-flex flex-row flex-wrap  mer-margin-left-12 mer-margin-top-12">
            <div className="d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12">
              <Card className="flex-grow-1 secondary">
                <div className="d-flex flex-row justify-content-between mer-margin-bottom-8">
                  <div className="mer-font-bold">Name</div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  <div
                    className="mer-width-percentage-45 mer-margin-right-8"
                    title="First Name"
                  >
                    First Name
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="mer-width-percentage-50"
                    title={registrationDetails?.first_name}
                  >
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.first_name
                    ) ? (
                      "-"
                    ) : (
                      <div className="mer-text-wrapping">
                        {registrationDetails?.first_name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  <div
                    className="mer-width-percentage-45 mer-margin-right-8"
                    title="Middle Name"
                  >
                    Middle Name
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="mer-width-percentage-50"
                    title={registrationDetails?.middle_name}
                  >
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.middle_name
                    ) || registrationDetails?.middle_name === "" ? (
                      "-"
                    ) : (
                      <div className="mer-text-wrapping">
                        {registrationDetails?.middle_name}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex  align-items-start justify-content-between">
                  <div
                    className="mer-width-percentage-45 mer-margin-right-8"
                    title="Last Name"
                  >
                    Last Name
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="mer-width-percentage-50"
                    title={registrationDetails?.last_name}
                  >
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.last_name
                    ) ? (
                      "-"
                    ) : (
                      <div className="mer-text-wrapping">
                        {registrationDetails?.last_name}
                      </div>
                    )}
                  </div>
                </div>
              </Card>
            </div>
            <div className="d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12">
              <Card className="flex-grow-1 secondary">
                <div className="d-flex flex-row justify-content-between mer-margin-bottom-8">
                  <div className="mer-font-bold">Contact</div>
                </div>
                <div className="d-flex  align-items-start justify-content-between">
                  <div className="mer-width-percentage-45 mer-margin-right-8 ">
                    Email
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="mer-width-percentage-50 ">
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.email_id
                    ) ? (
                      "-"
                    ) : (
                      <div className="mer-text-wrapping">
                        {registrationDetails?.email_id}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  <div className="mer-width-percentage-45 mer-margin-right-8">
                    Mobile
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="mer-width-percentage-50">
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.mobile_number
                    )
                      ? "-"
                      : CommonMethods.formatPhoneNumber(
                          registrationDetails?.mobile_number,
                          registrationDetails.country_code
                            ? registrationDetails.country_code.toUpperCase()
                            : CommonMethods.DEFAULT_COUNTRY_CODE
                        )}
                  </div>
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  <div className="mer-width-percentage-45 mer-margin-right-8">
                    Alternate Mobile
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div className="mer-width-percentage-50">
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.alt_mobile_number
                    )
                      ? "-"
                      : CommonMethods.formatPhoneNumber(
                        registrationDetails?.alt_mobile_number,
                        registrationDetails.alt_country_code
                          ? registrationDetails.alt_country_code.toUpperCase()
                          : CommonMethods.DEFAULT_COUNTRY_CODE
                      )}
                  </div>
                </div>
              </Card>
            </div>
            <div className="d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12">
              <Card className="flex-grow-1 secondary">
                <div
                  className="mer-font-bold  mer-margin-bottom-8"
                  title="Applied for"
                >
                  Applied for
                </div>
                <div className="d-flex  align-items-start justify-content-between">
                  <div
                    className="mer-width-percentage-45 mer-margin-right-8"
                    title="Domain"
                  >
                    Domain
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="mer-width-percentage-50"
                    title={registrationDetails?.domain_name}
                  >
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.domain_name
                    )
                      ? "-"
                      : registrationDetails?.domain_name}
                  </div>
                </div>
                <div className="d-flex  align-items-start justify-content-between">
                  <div
                    className="mer-width-percentage-45 mer-margin-right-8"
                    title="Department"
                  >
                    Department
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="mer-width-percentage-50"
                    title={registrationDetails?.department_name}
                  >
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.department_name
                    )
                      ? "-"
                      : registrationDetails?.department_name}
                  </div>
                </div>
                <div className="d-flex  align-items-start justify-content-between">
                  <div
                    className="mer-width-percentage-45 mer-margin-right-8"
                    title="Sub Department"
                  >
                    Sub Department
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="mer-width-percentage-50"
                    title={registrationDetails?.sub_department_name}
                  >
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.sub_department_name
                    )
                      ? "-"
                      : registrationDetails?.sub_department_name}
                  </div>
                </div>
                <div className="d-flex  align-items-start justify-content-between">
                  <div
                    className="mer-width-percentage-45 mer-margin-right-8"
                    title="Designation"
                  >
                    Designation
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="mer-width-percentage-50 "
                    title={registrationDetails?.designation_name}
                  >
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.designation_name
                    )
                      ? "-"
                      : registrationDetails?.designation_name}
                  </div>
                </div>
              </Card>
            </div>
            <div className="d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12">
              <Card className="flex-grow-1 secondary">
                <div className="d-flex flex-row justify-content-between mer-margin-bottom-8">
                  <div className="mer-font-bold">Other Details</div>
                </div>
                <div className="d-flex  align-items-start justify-content-between">
                  <div
                    className="mer-width-percentage-45 mer-margin-right-8"
                    title="Resume Source"
                  >
                    Resume Source
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="mer-width-percentage-50"
                    title={registrationDetails?.resume_source_name}
                  >
                    {CommonMethods.isNullOrUndefined(
                      registrationDetails?.resume_source_name
                    )
                      ? "-"
                      : registrationDetails.resume_source_name}
                  </div>
                </div>
              </Card>
            </div>
            <div className="d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12">
              <Card className="flex-grow-1 secondary">
                <div className="d-flex flex-row justify-content-between mer-margin-bottom-8">
                  <div className="mer-font-bold">Overall Experience</div>
                </div>
                <div className="d-flex  align-items-start justify-content-between">
                  <div
                    className="mer-width-percentage-45 mer-margin-right-8"
                    title="Experience"
                  >
                    Experience
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="mer-width-percentage-50"
                    title={
                      registrationDetails?.is_experienced
                        ? registrationDetails?.experience_years +
                          " years, " +
                          registrationDetails?.experience_months +
                          " months"
                        : "No prior experience"
                    }
                  >
                    {registrationDetails?.is_experienced
                      ? (!CommonMethods.isNullOrUndefined(
                          registrationDetails?.experience_years
                        )
                          ? registrationDetails?.experience_years + " years, "
                          : "") +
                        (!CommonMethods.isNullOrUndefined(
                          registrationDetails?.experience_months
                        )
                          ? registrationDetails?.experience_months + " months"
                          : "")
                      : "No prior experience"}
                  </div>
                </div>
              </Card>
            </div>
            <div className="d-flex flex-column col-md-6 col-lg-4 col-sm-12 col-12 col-xs-12">
              <Card className="flex-grow-1 secondary">
                <div className="d-flex flex-row justify-content-between mer-margin-bottom-8">
                  <div className="mer-font-bold">Resume</div>
                </div>
                <div className="d-flex flex-row">
                  <div
                    className="mer-width-percentage-45 mer-margin-right-8"
                    title="File"
                  >
                    File
                  </div>
                  <div className="mer-width-percentage-5">:</div>
                  <div
                    className="mer-width-percentage-50"
                    title={
                      registrationDetails?.attachments?.resume?.resume_original
                    }
                  >
                    <a
                      href={fileLink}
                      target="_blank"
                      className="mer-text-wrapping mer-color2"
                    >
                      {
                        registrationDetails?.attachments?.resume
                          ?.resume_original
                      }
                    </a>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Card>
      ) : (
        <Card className="d-flex flex-column">
          <SkeletonRegpopup></SkeletonRegpopup>
        </Card>
      )}
    </div>
  );
}

export default SourcedProfileRegistrationDetails
