import { useState, useEffect } from "react";
import * as API from "../../utills/API";
import * as CommonMethods from "../CommonMethods";
import axios from "axios";

/**
 * @description A custom hook to get the blood groups from remote. Can be modified to pass a custom url for the resource.
 * @returns Blood groups
 */
const useCountryCodes = () => {

    
  const [allowedCountries, setAllowedCountries] = useState(null);
  const [countriesWithRegexList, setCountriesWithRegexList] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState(null);
  const [loading, setLoading] = useState(true);


  function fetchCountryCodeswithRegex() {
    return new Promise((resolve, reject) => {
      API.GetMethod("countries/web/dial-code").then((res) => {
        if (!CommonMethods.isNullOrUndefined(res.data)) {
          setCountriesWithRegexList(res.data);
          let countryCodes = res.data.map(item => item.country_code);
          setAllowedCountries(countryCodes);
        }
        resolve();
      }).catch((error) =>{
        setAllowedCountries([]);
        reject(error);
      });
    });
  }

  const fetchDefaultCountryCode = async () => {
    try {
      const geoLocationDetails = await axios.get("https://ipapi.co/json/");
      const code = geoLocationDetails?.data?.country_code || "in";
      setDefaultCountry(code.toLowerCase());
    } catch (error) {
      setDefaultCountry("in");
    }
  };


  useEffect(() => {
    const fetchData = async () => {
        setLoading(true);
        await Promise.all([
            fetchCountryCodeswithRegex(),
            fetchDefaultCountryCode()
        ]).finally(() => {
            setLoading(false);
        });
    }

    fetchData();
  }, []);

  return { countriesWithRegexList ,allowedCountries, defaultCountry, loading };
};

export default useCountryCodes;