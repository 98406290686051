/* eslint-disable no-unused-vars */
import React from "react";
import * as XLSX from "xlsx";
import { Snackbar } from "@mui/material";
import { FiThumbsUp } from "react-icons/fi";
import {
  MdOutlineErrorOutline,
  MdOutlineWarningAmber,
  MdWarningAmber,
} from "react-icons/md";
import { StaticRouterLinks } from "./constants/StaticRouterLinks";
import * as Messages from "./constants/Messages";
import { status_dialog } from "./Alerts";
import axios from "axios";
import { parsePhoneNumber, parsePhoneNumberFromString } from 'libphonenumber-js';

export const MAX_DOB_DATE = new Date().setFullYear(
  new Date().getFullYear() - 18
);
export const MIN_DOB_DATE = new Date().setFullYear(
  new Date().getFullYear() - 80
);
export const concateNames = (firstName, middleName, lastName) => {
  return (
    (!isNullOrUndefined(firstName) ? firstName.toString() + " " : "") +
    (!isNullOrUndefined(middleName) ? middleName.toString() + " " : "") +
    (!isNullOrUndefined(lastName) ? lastName.toString() : "")
  );
};

export const removeEmptyObjectFromArray = (data) => {
  return JSON.stringify(data) === "[{}]" ? [] : data;
};

export const isNullOrUndefined = (value) => {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    (typeof value === "object" ? Object.keys(value).length === 0 : false)
  );
};
export const getProperArrayFormat = (value) => {
  let string_format = JSON.stringify(value);
  let return_format = string_format === "[{}]" ? [] : JSON.parse(string_format);
  return return_format;
};
export const getEventInfo = (form_id, base_path, button_value) => {
  let trigger_at = base_path.split("/").pop();
  trigger_at = trigger_at
    .toString()
    .concat("-")
    .concat(button_value.toLowerCase());

  //     if(!isNullOrUndefined(event_info['form_id']) && !isNullOrUndefined(event_info['trigger_at'])){
  //     event_info['form-id'] = event_info['form_id'];
  //     event_info['trigger-at'] = event_info['trigger_at'];
  //     event_info['Form-Id'] = event_info['form_id'];
  //     event_info['Trigger-At'] = event_info['trigger_at'];
  //   }
  return {
    "Form-Id": form_id,
    "Trigger-At": trigger_at,
  };
};

export const DEFAULT_COUNTRY_CODE = 'IN';
export const ROWS_PER_PAGE = [10, 25, 50, 100];
export const DEFAULT_ROWS_PER_PAGE = 25;
export const ALERT_TIMEOUT = 15000;
export const UPDATE_BUTTON = "Update";
export const CREATE_BUTTON = "Create";
export const ADDNEW_BUTTON = "Add New";
export const NO_INTERVIEW =
  "There is no interview scheduled for you with this candidate.";
export const reg_mail = /^[^A-Z]*$/;
export const reg_name_with_space = /^[a-zA-Z ]*$/;
export const reg_name_without_space = /^[a-zA-Z]*$/;
export const reg_letter_digits = /^[a-zA-Z0-9]*$/;

export const WORK_EXPERIENCE_GAP_MONTHS = 3;
export const ACADEMICS_GAP_YEARS = 1;

export const EXCEPTION_MONTHS_TILL_5YEARS_EXPERIENCE = 6;
export const EXCEPTION_MONTHS_TILL_10YEARS_EXPERIENCE = 12;
export const EXCEPTION_MONTHS_MORETHAN_10YEARS_EXPERIENCE = 18;

export const PAGINATION_ROW_LABEL = "Rows :";
export const reg_mob = /^[6-9]+[0-9]*$/;
export const max_file_size = 8388608;
export const max_file_size_string = "8MB";
export const max_image_size = 1048576;
export const max_image_size_string = "1MB";

export const CANDIDATE = {
  INTERVIEW: {
    MY_COMMENTS_NOT_AVAILABLE:
      "Please use the below button to submit your comments.",
    RATINGS_NOT_AVAILABLE: "Please add at least one question",
  },
};

export const err_msg = {
  mail: "Capital letters are not allowed",
  name: "Numbers and special char are not allowed",
  mob: "Invalid mobile number",
  min_ten: "Minimum 10 digits are required",
  max_ten: "Maximum 10 digits are allowed",
  mail_char: "Cannot be great than 64 characters",
  special_chars: "Special characters are not allowed",
};
export const headerData = [
  { name: "Home", form_control_name: "home" },
  { name: "Candiates", form_control_name: "candidates" },
  { name: "Analytics", form_control_name: "analytics" },
  { name: "Interviews", form_control_name: "interviews" },
  { name: "Global Search", form_control_name: "global_search" },
  { name: "Profile Form", form_control_name: "profile_form" },
  { name: "Bulk Upload", form_control_name: "bulk_upload" },
  { name: "Archived Profiles", form_control_name: "archived_profiles" },
  { name: "Settings", form_control_name: "settings" },
];
export const candidateActions = [
  { name: "Timeline", form_control_name: "timeline" },
  { name: "Reject", form_control_name: "reject" },
  { name: "Forward", form_control_name: "forward" },
  { name: "Overview", form_control_name: "overview" },
];
export const statusFieldConversion = (status) => {
  return status ? "Active" : "Inactive";
};
export const interveiewFieldConversion = (interview) => {
  return interview ? "Yes" : "No";
};

export const CapitalizeFirstLetter = (mySentence) => {
  return mySentence.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
};

export const exportJSONtoExcel = (data, name) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  XLSX.writeFile(workbook, name);
};
export const copyToClipboard = (text) => {
  var textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};
export const test_locations_list = [
  {
    _id: "None",
    location: "None",
  },
  {
    _id: "Gutenberg",
    location: "Gutenberg",
  },
];
export const yes_or_no_array = [
  {
    _id: true,
    label: "Yes",
  },
  {
    _id: false,
    label: "No",
  },
];
export const updateFormFields = (obj1, obj2) => {
  for (let prop of Object.keys(obj2)) {
    if (prop in obj1) {
      if (
        typeof obj2[prop] === "object" &&
        Array.isArray(obj2[prop]) &&
        !isNullOrUndefined(obj2[prop][0])
      ) {
        if (
          !isNullOrUndefined(obj1[prop][0]) &&
          Object.keys(obj1[prop][0]).length
        ) {
          let objectKeys = { ...obj1[prop][0] };
          obj1[prop] = [];
          for (let i = 0; i < obj2[prop].length; i++) {
            let object = { ...objectKeys };
            for (let nestedProp of Object.keys(objectKeys)) {
              if (nestedProp in object) {
                object[nestedProp] = !isNullOrUndefined(
                  obj2[prop][i][nestedProp]
                )
                  ? obj2[prop][i][nestedProp]
                  : objectKeys[nestedProp];
              }
            }
            obj1[prop].push(object);
          }
        } else {
          obj1[prop] = !isNullOrUndefined(obj2[prop]) ? obj2[prop] : obj1[prop];
        }
      } else if (
        typeof obj2[prop] === "object" &&
        !Array.isArray(obj2[prop]) &&
        !isNullOrUndefined(obj2[prop])
      ) {
        if (!isNullOrUndefined(obj1[prop]) && Object.keys(obj1[prop]).length) {
          for (let nestedProp of Object.keys(obj2[prop])) {
            if (nestedProp in obj1[prop]) {
              obj1[prop][nestedProp] = !isNullOrUndefined(
                obj2[prop][nestedProp]
              )
                ? obj2[prop][nestedProp]
                : obj1[prop][nestedProp];
            }
          }
        } else {
          obj1[prop] = !isNullOrUndefined(obj2[prop]) ? obj2[prop] : obj1[prop];
        }
      } else if (typeof obj2[prop] !== "object") {
        obj1[prop] = !isNullOrUndefined(obj2[prop]) ? obj2[prop] : obj1[prop];
      }
    }
  }

  return obj1;
};

export const arrayToString = (arrayName, fieldName) => {
  if (!isNullOrUndefined(arrayName)) {
    let newArray = [];
    for (let i = 0; i < arrayName.length; i++) {
      newArray.push(arrayName[i][fieldName]);
    }
    let arrayData = newArray.join(", ");
    return arrayName.length !== 0 ? arrayData : "NA";
  } else {
    return isNullOrUndefined(arrayName)
      ? "NA"
      : arrayName.length !== 0
        ? ""
        : "NA";
  }
};

export function displayDate(date) {
  let d = new Date(date);
  let months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[d.getMonth()] + ", " + d.getDate() + ", " + d.getFullYear();
}

export const yesNOFieldConversion = (status) => {
  return status ? "Yes" : "No";
};

export function sortByProperty(property) {
  return function (a, b) {
    if (isNullOrUndefined(property)) {
      if (a > b) return 1;
      else if (a < b) return -1;

      return 0;
    } else {
      if (a[property] > b[property]) return 1;
      else if (a[property] < b[property]) return -1;
      return 0;
    }
  };
}

export function customSort(data, key, sortType) {
  if (!isNullOrUndefined(data)) {
    let ascSort = data.sort(sortByProperty(key));
    return sortType === "desc" ? ascSort.reverse() : ascSort;
  } else {
    return [];
  }
}

export function customShowornot(data, key, sortType, show_only_access) {
  if (show_only_access) {
    return data.filter((el) => {
      return el[key] === true;
    });
  } else {
    return customSort(data, key, sortType);
  }
}
/**
 *
 */
export function insertobjectToarray(arr, obj, index) {
  if (index < arr.length) {
    arr[index] = obj;
    return arr;
  } else {
    arr.push(obj);
    return arr;
  }
}

export const SHOW_OPTIONS_ONLY_HAS_ACCESS = {
  registration: false,
  sourcedprofile: true,
  candidatetables: true,
  archivetables: true,
  referralprofile: true,
};

export const STATUS_POPUP_DETAILS = {
  success: {
    iconName: <FiThumbsUp size="1.4rem" />,
    title: "Success",
    message: "",
    className: "mer-success-dialog",
  },
  error: {
    iconName: <MdOutlineErrorOutline size="1.4rem" />,
    title: "Error",
    message: "",
    className: "mer-error-dialog",
  },
  access_restore: {
    iconName: <MdOutlineErrorOutline size="1.4rem" />,
    title: "Alert",
    message: "",
    className: "mer-access-dialog",
  },
  warning: {
    iconName: <MdWarningAmber size="1.4rem" />,
    title: "Warning",
    message: "",
    className: "",
  },
};

export function RANGE_ARRAY(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export const app_support_details = {
  name: "Contact Talent Acquisition",
  email: "AccordionDA.Recruitment@accordion.com",
};
export function numberFormat(number) {
  return number?.toLocaleString("en-IN");
}
export function maxYearofPass() {
  let today = new Date();
  let year = today.getFullYear();
  year = year + 3;
  let max_year = new Date(year, 0, 1);
  return max_year;
}
export const routers_list = [
  {
    tab_name: "Candidate Overview",
    link: StaticRouterLinks.candidate_overview,
  },
  {
    tab_name: "Registration Details",
    link: StaticRouterLinks.registration_details,
  },
  {
    tab_name: "Personal Details",
    link: StaticRouterLinks.personal_details,
  },
  {
    tab_name: "History",
    link: StaticRouterLinks.history,
  },
  {
    tab_name: "Skills",
    link: StaticRouterLinks.skills,
  },
  {
    tab_name: "Tests",
    link: StaticRouterLinks.tests,
  },
  {
    tab_name: "Preferences",
    link: StaticRouterLinks.preferences_view,
  },
  {
    tab_name: "Role to be considered",
    link: StaticRouterLinks.roles_to_be_considered,
  },
  {
    tab_name: "Schedule Interview",
    link: StaticRouterLinks.schedule_interviews,
  },
  {
    tab_name: "Enter my comments",
    link: StaticRouterLinks.my_comments,
  },
  {
    tab_name: "All Interviews",
    link: StaticRouterLinks.all_interviews,
  },
  {
    tab_name: "Documents",
    link: StaticRouterLinks.documents_view,
  },
  {
    tab_name: "Proposal",
    link: StaticRouterLinks.proposal_view,
  },
];

export function getFilteredData(techinterview, question_id, returnValue) {
  let item = techinterview?.ratings?.find(
    (id) => id?.question_id === question_id
  );
  if (!isNullOrUndefined(returnValue)) {
    if (returnValue === "rating") {
      return item?.rating;
    }
    if (returnValue === "comment") {
      return item?.comment;
    }
  } else {
    return true;
  }
}

export function workExeperienceCalculation(values) {
  let experience_years = JSON.parse(JSON.stringify(values?.experience_years));
  let experience_months = JSON.parse(JSON.stringify(values?.experience_months));
  let total_experience_in_months = experience_years * 12 + experience_months;

  let most_recent_experience = JSON.parse(
    JSON.stringify(values.most_recent_experience)
  );
  most_recent_experience.from_date = new Date(most_recent_experience.from_date);
  if (most_recent_experience?.is_current_company) {
    most_recent_experience.to_date = new Date();
  } else {
    most_recent_experience.to_date = new Date(most_recent_experience.to_date);
  }

  const experiences = JSON.parse(JSON.stringify(values.experiences));

  experiences.forEach((experience) => {
    experience.from_date = new Date(experience.from_date);
    experience.to_date = new Date(experience.to_date);
  });

  const work_experience = [most_recent_experience, ...experiences];

  // work_experience.sort((a, b) => { return a.from_date > b.from_date });
  // console.log(work_experience);
  let numberOfMonths = 0;
  let array = [];
  work_experience.forEach((work_experience) => {
    // numberOfMonths = numberOfMonths + ((work_experience.to_date.getFullYear() - work_experience.from_date.getFullYear()) * 12 + (work_experience.to_date.getMonth() - work_experience.from_date.getMonth()));
    for (let i = work_experience.from_date; i <= work_experience.to_date;) {
      let object = {
        year: i.getFullYear(),
        month: i.getMonth(),
      };
      let obj = array.find(
        (x) => x.month === object.month && x.year === object.year
      );
      if (isNullOrUndefined(obj)) {
        array.push(object);
      }
      i.setMonth(i.getMonth() + 1);
    }
  });
  // console.log(array, total_experience_in_months)
  numberOfMonths = array?.length;
  if (numberOfMonths <= total_experience_in_months + 1) {
    if (total_experience_in_months <= 60) {
      if (
        numberOfMonths >=
        total_experience_in_months - EXCEPTION_MONTHS_TILL_5YEARS_EXPERIENCE
      ) {
        return true;
      } else {
        let status_details = {
          ...STATUS_POPUP_DETAILS.error,
          message: Messages.error_messages.experience_details,
        };
        status_dialog(status_details, () => { });
        return false;
      }
    } else if (
      total_experience_in_months > 60 &&
      total_experience_in_months <= 120
    ) {
      if (
        numberOfMonths >=
        total_experience_in_months - EXCEPTION_MONTHS_TILL_10YEARS_EXPERIENCE
      ) {
        return true;
      } else {
        let status_details = {
          ...STATUS_POPUP_DETAILS.error,
          message: Messages.error_messages.experience_details,
        };
        status_dialog(status_details, () => { });
        return false;
      }
    } else {
      if (
        numberOfMonths >=
        total_experience_in_months -
        EXCEPTION_MONTHS_MORETHAN_10YEARS_EXPERIENCE
      ) {
        return true;
      } else {
        let status_details = {
          ...STATUS_POPUP_DETAILS.error,
          message: Messages.error_messages.experience_details,
        };
        status_dialog(status_details, () => { });
        return false;
      }
    }
  } else {
    let status_details = {
      ...STATUS_POPUP_DETAILS.error,
      message: Messages.error_messages.experience_details,
    };
    status_dialog(status_details, () => { });
    return false;
  }
}
export function replaceUnderScore(string) {
  return string?.replace(/_/g, " ");
}

export async function getLoginUrl() {
  const config = {
    // Replace domain here with production value through env
    headers: {
      // domain: `${process.env.REACT_APP_BASE_URL}/`,
      domain: `${process.env.REACT_APP_FRONTEND_DOMAIN}`,
    },
  };
  const login_resp = await axios.get(
    `${process.env.REACT_APP_MICROSOFT_OAUTH_URL}/api-oauth/v1/oauth/microsoft/login/getLoginUrl`,
    config
  );
  window.sessionStorage.setItem(
    "verifier_token",
    login_resp?.data?.verifier_jwt
  );
  return login_resp?.data?.login_url;
}

export async function getTokenAndDetails(code) {
  const options = {
    headers: {
      Authorization: `${code}`,
      "Code-Verifier": window.sessionStorage.getItem("verifier_token") ?? "",
    },
  };

  try {
    const responses = await axios.get(
      `${process.env.REACT_APP_MICROSOFT_OAUTH_URL}/api-oauth/v1/oauth/microsoft/login/getAccessToken`,
      options
    );

    return responses.data;
  } catch (err) {
    console.log("Retrieving Access Token failed");
    // Logout();
  }
}

export async function setTokensToLocalStorage(token_obj) {
  await window.localStorage.setItem(
    "microsoft_tokens",
    JSON.stringify(token_obj)
  );
}

export async function Logout() {
  window.sessionStorage.clear();
  window.localStorage.clear();
  window.location.reload();
}

export function base64ToBlob(base64, mime) {
  const byteString = atob(base64.split(",")[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mime });
}

export async function urltoFile(url, filename, mimeType) {
  if (url.startsWith("data:")) {
    var arr = url.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    var file = new File([u8arr], filename, { type: mime || mimeType });
    return Promise.resolve(file);
  }
  return fetch(url)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], filename, { type: mimeType }));
}

export const FIELDS_TO_CHECK_FOR_EMPLOYEEDB_SYNC = ["employee_type_id", "is_employee_rehire", "employee_hire_type_id", "employee_location_id"]

export const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
  ],
};


export const formatPhoneNumber = (number, code) =>{
  let parsedNumber = parsePhoneNumberFromString(number, code);
  let finalNumber = parsedNumber.formatInternational();
  return finalNumber;
}

export const getMobileNumberPattern = (countriesWithRegexList, code) => {
  const country = countriesWithRegexList.find(item => item.country_code === code);
  return country ? country.mobile_number_pattern : null;
};