import React from "react";
import { TextField, FormLabel } from "@mui/material";
import FormikErrorField from "./FormikErrorField";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
/**
 *
 * @param {*} formikProp - The formik instances returned by useFormik.
 * @param label - The label to the select field.
 * @param labelProps - The props for modifiying the inputLabel
 * @param name - The name to the underlying form field.
 * @param useTopLabel - Defaults to true.Uses the top label instead of Material label.
 * @param useMaterialLabel - Defualts to false. Uses the Material style labels. Enabling this overrides the useTopLabel's behavior.
 * @param placeholder - The placeholder to the underlying form field.
 * @param mandatory - Defaults to true. Displays a red star on the inputLabel.
 * @param errorFieldProps - The props passed to the underlying error component.
 * @param disableError - Defaults to false. Disables the error messages under the field.
 * @param attr - The remaining props accepted by MuiTextField, passed directly to the underlying MuiTextField.
 * @description A custom text field based on formik & Mui to reduce the boiler plate while writing forms. Inherently handles the onChange, onBlur, mandatory, error feedback & labels.
 *
 */
const RPPhoneInputField = ({
  label,
  labelProps,
  name,
  formikProp,
  errorFieldProps,
  wrapperProps = {
    className: "d-flex flex-column flex-grow-1",
  },
  mandatory = true,
  useTopLabel = true,
  useMaterialLabel = false,
  disableError = false,
  dependent_field,
  ...attr
}) => {
  const value = (on) => {
    const dots = name.split(".");
    let Value = formikProp?.[on];
    dots.forEach((dot) => {
      Value = Value?.[dot];
    });
    return Value;
  };

  return (
    <div {...wrapperProps}>
      <FormLabel className={labelProps?.className} id={name}>
        {label}
        {mandatory ? <span className="mer-color-red">*</span> : ""}
      </FormLabel>
      <PhoneInput
        name={name}
        value={value("values")}
        countryCodeEditable={false}
        onChange={(value, { countryCode: selectedCountryCode }) => {
          // console.log("value" , value, selectedCountryCode, formikProp.values[name], formikProp.values[dependent_field])
          // if (formikProp.values[dependent_field] !== selectedCountryCode && formikProp.values[name] ==  value) {
          //   formikProp.setFieldValue(name, "");
          // } else {
            formikProp.setFieldValue(dependent_field, selectedCountryCode);
            formikProp.setFieldValue(name, value);
          // }
        }}
        onBlur={() => formikProp.setFieldTouched(name, true)}
        inputStyle={{
          width: "100%",
          border: value("touched") && value("errors")
              ? "1px solid red"
              : "1px solid #ccc",
          borderRadius: "4px",
        }}
        {...attr}
      />
      {!disableError && (
        <FormikErrorField
          formikProp={formikProp}
          name={name}
          className={errorFieldProps?.className}
        />
      )}
    </div>
  );
};

export default RPPhoneInputField;
