import { Button, Card, Typography,useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { IoMdCloseCircleOutline } from 'react-icons/io';
import * as CommonMethods from "../../utills/CommonMethods";
// import * as API from "../../utills/API";
import { DateTimeFormat } from '../../utills/DateTimeFormats';
import useDeviceProperties from '../../utills/hooks/useDeviceProperties';
// import Skeletonview from '../../components/Skeletons/Skeletonview';
import IMAGES from '../../utills/constants/Images';

function CandidateDuplicateApplicationDetails(props) {
    let deviceProperties = useDeviceProperties();
    let theme=useTheme();

    useEffect(() => {
    }, [])

    return (
        <div className='d-flex flex-grow-1 flex-column mer-overflow-hidden' style={{ backgroundImage: `url(${ theme.palette.mode==="light" ?IMAGES.page_background_light:IMAGES.page_background_dark})` }}>
            <Card className='mer-theme-dialog-header-bg-tables d-flex flex-row align-items-center justify-content-between mer-padding-16'  >
                <div className="d-flex align-items-center mer-margin-right-8">{props?.title}</div>
                <IoMdCloseCircleOutline onClick={() => { props.onChange() }}
                    className='mer-min-height-24 mer-min-width-24 mer-cursor-pointer' />
            </Card>
            {/* {dataLoaded ? */}
                <div className='d-flex flex-column mer-padding-left-12 mer-padding-right-12 mer-padding-top-12 flex-grow-1 mer-overflow'>
                    {props?.previous_application_details?.length > 0 ? 
                    <div className='d-flex flex-grow-1 flex-column'>
                        <div className='d-flex flex-grow-1 flex-column'>
                    {props?.previous_application_details?.map((profile, index) => (
                        <Card key={index} className="secondary mer-margin-right-0">
                            <div className='d-flex flex-wrap'>
                                <div className='d-flex align-items-start col-md-12 col-lg-6 col-sm-12 col-xs-12 col-12'>
                                    <div className={deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice ? 'col-6 mer-font-bold' : 'col-5 mer-font-bold'}>Full Name&nbsp; </div>
                                    <div className='d-flex align-items-start col-6'>:&nbsp;
                                        <div className='mer-padding-left-5'>{profile?.full_name ? profile.full_name : '-'}</div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start col-md-12 col-lg-6 col-sm-12 col-xs-12 col-12'>
                                    <div className={deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice ? 'col-6 mer-font-bold' : 'col-5 mer-font-bold'}>Status&nbsp;</div>
                                    <div className='d-flex align-items-start col-6'>:&nbsp;
                                        <div className='mer-padding-left-5'>{profile?.status_name ? profile.status_name : '-'}</div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start col-md-12 col-lg-6 col-sm-12 col-xs-12 col-12'>
                                    <div className={deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice ? 'col-6 mer-font-bold' : 'col-5 mer-font-bold'}> Email&nbsp;</div>
                                    <div className='d-flex align-items-start col-6'>:&nbsp;
                                        <div className='mer-padding-left-5' style={{ width: '95%', whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{profile?.email_id ? profile.email_id : '-'}</div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start col-md-12 col-lg-6 col-sm-12 col-xs-12 col-12'>
                                    <div className={deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice ? 'col-6 mer-font-bold' : 'col-5 mer-font-bold'}> Mobile Number&nbsp; </div>
                                    <div className='d-flex align-items-start col-6'>:&nbsp;
                                        <div className='mer-padding-left-5'>{profile?.mobile_number ?  CommonMethods.formatPhoneNumber(profile?.mobile_number, profile.country_code ? profile.country_code.toUpperCase() : CommonMethods.DEFAULT_COUNTRY_CODE) : '-'}</div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start col-md-12 col-lg-6 col-sm-12 col-xs-12 col-12'>
                                    <div className={deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice ? 'col-6 mer-font-bold' : 'col-5 mer-font-bold'}>Domain&nbsp;</div>
                                    <div className='d-flex align-items-start col-6'>:&nbsp;
                                        <div className='mer-padding-left-5'>{profile?.domain_name ? profile.domain_name : '-'}</div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start col-md-12 col-lg-6 col-sm-12 col-xs-12 col-12'>
                                    <div className={deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice ? 'col-6 mer-font-bold' : 'col-5 mer-font-bold'}>Department&nbsp;</div>
                                    <div>:&nbsp;</div>
                                    <div className='mer-padding-left-5'>{profile?.department_name ? profile.department_name : '-'}</div>
                                </div>
                                <div className='d-flex align-items-start col-md-12 col-lg-6 col-sm-12 col-xs-12 col-12'>
                                    <div className={deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice ? 'col-6 mer-font-bold' : 'col-5 mer-font-bold'}>Sub Department&nbsp;</div>
                                    <div className='d-flex align-items-start col-6'>:&nbsp;
                                        <div className='mer-padding-left-5'>{profile?.sub_department_name ? profile.sub_department_name : '-'}</div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start col-md-12 col-lg-6 col-sm-12 col-xs-12 col-12'>
                                    <div className={deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice ? 'col-6 mer-font-bold' : 'col-5 mer-font-bold'}>Designation&nbsp;</div>
                                    <div className='d-flex align-items-start col-6'>:&nbsp;
                                        <div className='mer-padding-left-5'>{profile?.designation_name ? profile.designation_name : '-'}</div>
                                    </div>
                                </div>
                                <div className='d-flex align-items-start col-md-12 col-lg-6 col-sm-12 col-xs-12 col-12'>
                                    <div className={deviceProperties?.isMobileDevice || deviceProperties?.isTabletDevice ? 'col-6 mer-font-bold' : 'col-5 mer-font-bold'}>Application ID&nbsp;</div>
                                    <div className='d-flex align-items-start col-6'>:&nbsp;
                                        <div className='mer-padding-left-5' style={{ width: '95%', whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>{profile?._id ? profile._id : profile?.profile_id ? profile.profile_id : '-'}</div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between align-items center  mer-margin-top-8'>
                                <div className='d-flex justify-content-start'>
                                    {
                                    // profile?._id === props?.candidate?._id ?
                                    // <Card className='chipsTheme mer-font-size-12 mer-margin-0 mer-padding-left-8 mer-padding-right-8  mer-padding-top-4 mer-padding-bottom-4'>Current application</Card>
                                    // : 
                                    profile?.profile_id ? <Card className='mer-theme-error-card mer-font-size-12 mer-margin-0 mer-padding-left-8 mer-padding-right-8  mer-padding-top-4 mer-padding-bottom-4'>Old tool record</Card>
                                        : <></>}</div>
                                <div className='d-flex justify-content-end mer-font-size-12'>
                                    {profile?.created_on ? DateTimeFormat(profile.created_on) : null}
                                </div>
                            </div>

                        </Card>
                    ))}</div>
                        <div className='d-flex justify-content-end align-self-end align-items-center mer-padding-16'>
                        <div className='d-flex justify-content-end'>
                            <Button variant="outlined" className='mer-margin-right-8 outlinedcls' 
                            onClick={() => {props.onChange()}} >Discard</Button>
                            <Button variant="contained" className='' type="submit"  onClick={() => {props.onChange(1)}}>Create</Button>
                        </div>
                    </div>
                        </div>
                    : <div className='d-flex flex-grow-1 justify-content-center align-items-center'>No data found</div>}

                </div>
                 {/* : <Skeletonview /> */}
        </div >
    )
}

export default CandidateDuplicateApplicationDetails
