import React from "react";

/**
 *
 * @param formikProp - is the form state handler which controls the form input component
 * @param name - the name of the input component
 * @param className - the classNames to add custom styles to the error component
 */
const FormikErrorField = ({ formikProp, name, className }) => {
  const value = (on) => {
    const dots = name.split(".");
    let errorValue = formikProp?.[on];
    dots.forEach((dot) => {
      errorValue = errorValue?.[dot];
    });
    return errorValue;
  };

  return (
    <span className={`mer-error ${className}`}>
      {value("touched") && value("errors") ? `${value("errors")}` : ""}
    </span>
  );
};

export default FormikErrorField;
